<template>
  <div id="container">
    <div id="center_container">
      <div>
        Page not found..
      </div>
      <v-btn
          depressed
          color="primary"
          rounded
          to="/"
      >
        Go To Home page
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>
  #container {
    height: 100vh;
    position: relative;
  }

  #center_container {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20pt;
  }
</style>